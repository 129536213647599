@font-face {
	font-family: 'Asap';
	font-display: auto;
	src: url('../img/fonts/Asap-Regular.otf') format('truetype');
	font-weight: normal;
}
@font-face {
	font-family: 'Asap';
	font-display: auto;
	src: url('../img/fonts/Asap-Bold.otf  ') format('truetype');
	font-weight: bold;
}

@tailwind preflight;
@tailwind components;
@tailwind utilities;

html {
	overflow: hidden !important;
}

body {
	font-family: 'Asap', serif;
}

.section--1 {
	background: url('../img/bananas-header.jpg') no-repeat;
	background-size: cover;
}

.flags-container {
	position: absolute;
	display: flex;
	z-index: 100;
	left: 90%;
	top: 20px;
}

.flag-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.flags-container .flag-container:not(:last-child) {
	margin-right: 20px;
}

.flag-container p {
	font-size: 0.7rem;
}

.flag img {
	height: 20px;
}

/* .text-hero {
	font-size: 4rem;
} */

.bars-container {
	display: none;
}

.s2-container-left {
	max-width: 400px;
	align-self: center;
	justify-self: center;
}

.section--2 {
	background: url('../img/bg-s2--a.jpg') no-repeat 100% 50%;
	background-size: cover;
	width: 100%;
	height: 100vh;
}

.section--2.s2-bg-content-2 {
	background: url('../img/bg-s2--c.jpg') no-repeat 90% 50%;
	background-size: cover;
	width: 100%;
	height: 100vh;
}

.section--2.s2-bg-content-3 {
	background: url('../img/bg-s2--d.png') no-repeat 125% 50%;
	background-size: cover;
	width: 100%;
	height: 100vh;
}

.section--2.s2-bg-content-4 {
	background: url('../img/bg-s2--e.png') no-repeat 100% 50%;
	background-size: cover;
	width: 100%;
	height: 100vh;
}

.divider--2 {
	width: 60px;
	border-bottom: 3px solid #325280;
	margin-bottom: 2rem;
}

#fp-nav ul li a span {
	background: white;
	/* background: #325280; */
}
#fp-nav ul li .fp-tooltip {
	font-weight: bold;
}

.myActive {
	color: #325280;
	font-weight: bold;
}

.divider {
	width: 100px;
	border-bottom: 3px solid white;
}

.bottom-bar,
.horizontal-bar,
.footer-icon {
	display: none;
}

.arrowUp,
.arrowDown {
	position: fixed;
	z-index: 99;
	cursor: pointer;
	width: 0;
	height: 0;
	border-style: solid;
	margin-left: -36px;
	left: 100%;
	border-width: 0 3px 3px 0;
	padding: 3px;
	/* border-color: white; */
}

.arrowDown {
	bottom: 245px;
	transform: rotate(45deg);
}

.arrowUp {
	top: 245px;
	transform: rotate(-135deg);
}

.myContainer {
	display: grid;
	grid-template-columns: 57.5% 52.5%;
	position: relative;
}

.myBox-container {
	max-width: 57.5%;
	margin-top: 4rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	grid-gap: 1.3rem;
}

.hidden {
	display: none !important;
}

.s5-p-content-2 span a {
	color: #325280;
}

.section--2b {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
		url('../img/bg-s2b.jpg') no-repeat 50% 50%;
	background-size: cover;
	width: 150%;
	height: 100vh;
}

.section--3 {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
		url('../img/bg-s3.jpg') no-repeat 50% 50%;
	background-size: cover;
}

.s3-container {
	max-width: 900px;
	margin: 0 auto;
}

.s3-card p {
	text-align: justify;
}

.s3-box-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.s3-card {
	transition: all 0.2s;
}

.s3-card:hover {
	transform: translateY(-5px);
}

.s2-link,
.s5-link {
	transition: all 0.1s;
}

.s2-link:hover,
.s5-link:hover {
	color: #325280;
	font-weight: bold;
}

.text-justify {
	max-width: 85%;
	line-height: 1.25rem;
}

.s2-p-content.text-justify,
.s5-p-content.text-justify {
	max-width: 100%;
}

ul.s2-p-content {
	list-style: none;
	display: grid;
	grid-template-areas: 'p' 'i';
}

ul.s2-p-content p {
	grid-area: p;
}

ul.s5-p-content {
	list-style: none;
}

.s4-container {
	max-width: 1100px;
	margin: 0 auto;
	margin-top: 8rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 4rem;
}

.s4-box {
	text-align: center;
	display: grid;
	grid-template-areas: 't t' 'l l' 'l l' 'l l';
	grid-column-gap: 2rem;
	justify-items: center;
}

.s4-box.s4-box--2 {
	display: grid;
	grid-template-rows: auto auto 1fr;
	grid-template-areas: unset;
	justify-items: center;
}

.s4-box.s4-box--2 h3 {
	grid-area: auto;
}

.s4-box h3 {
	grid-area: t;
}

.s4-box img {
	filter: grayscale(100%);
	transition: all ease .5s;
}

.s4-box img:hover {
	filter: grayscale(0);
}

.s4-box--2 img {
	filter: grayscale(100%);
	transition: all ease .5s;
}

.s4-box--2 img:hover {
	filter: grayscale(0);
}

.s5-container {
	display: grid;
	grid-template-columns: 57.5% 42.5%;
}

.s5-container-right {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
		url('../img/bg-s5-r.jpg') no-repeat 50% 50%;
	background-size: cover;
	height: 100vh;
	width: 100%;
}

.modal-s2a-2 .s3-modal-container ul {
	list-style-type: none;
	padding: 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.s5-container-right.s5-bg-content-1 {
	background: url('../img/bg-s5--b.jpg') no-repeat 50% 50%;
	background-size: cover;
	height: 100vh;
	width: 100%;
}

.s5-container-right.s5-bg-content-2 {
	background: url('../img/bg-s5--c.png') no-repeat 50% 50%;
	background-size: contain;
	height: 100vh;
	width: 70%;
}

.s5-container-left {
	align-self: center;
	max-width: 400px;
	margin: 0 auto;
	margin-top: 6rem;
}

.s6-container {
	max-width: 800px;
	margin: 0 auto;
	margin-top: 5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.s6-w-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-row-gap: 1rem;
	grid-column-gap: 1rem;
}

.modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 30; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
	-webkit-animation-name: fadeIn; /* Fade in the background */
	-webkit-animation-duration: 0.4s;
	animation-name: fadeIn;
	animation-duration: 0.4s;
}

.divider--3 {
	display: none;
	height: 3px;
	max-width: 60%;
	margin: 0 auto 4rem auto;
	background: #325280;
}

.close {
	color: white;
	float: right;
	font-size: 50px;
	font-weight: bold;
	margin-top: 0px;
	margin-right: 20px;
	transition: all 0.2s;
	position: relative;
	z-index: 1000;
}

.close:hover,
.close:focus {
	transform: scale(1.1);
	text-decoration: none;
	cursor: pointer;
}

.modal-content {
	max-width: 800px;
	height: 90vh;
	background: white;
	margin: 0 auto;
	margin-top: 2rem;
	margin-bottom: -2rem;
}

.modal-slider {
	position: relative;
	width: 100%;
}

.modal-arrow {
	position: absolute;
	z-index: 99;
	cursor: pointer;
	width: 0;
	height: 0;
	border-style: solid;
	border-color: gray;
	margin-left: -36px;
	top: 50%;
	border-width: 0 3px 3px 0;
	padding: 8px;
}

.modal-arrow-left {
	transform: rotate(135deg);
	left: 10%;
}

.modal-arrow-right {
	transform: rotate(315deg);
	right: 5%;
}

.s3-modal-container {
	padding: 1rem 1rem 1rem 2.5rem;
	display: grid;
	grid-template-columns: 40% 55%;
	grid-column-gap: 2rem;
}

.s3a-container-right {
	background: url('../img/bg-s3--a.png') no-repeat 50% 50%;
	background-size: cover;
	height: 85vh;
	position: relative;
}

.modal-rc-text {
	text-align: left;
	position: absolute;
	bottom: 0;
	right: 0;
}

.s3a-container-right.s3a-container-right--1 {
	background: url('../img/bg-s3--b.png') no-repeat 50% 50%;
	background-size: cover;
}

.s3a-container-right.s3a-container-right--2 {
	background: url('../img/bg-s3--c.png') no-repeat 50% 50%;
	background-size: cover;
}

.s3a-container-right.s3a-container-right--3 {
	background: url('../img/bg-s3--d.png') no-repeat 50% 50%;
	background-size: cover;
}

.s3a-container-right.s3a-container-right--2a {
	background: url('../img/bg-s3--2a.png') no-repeat 50% 50%;
	background-size: cover;
}

.s3a-container-right.s3a-container-right--2b {
	background: url('../img/bg-s3--2b.png') no-repeat 50% 50%;
	background-size: cover;
}

.s3a-container-right.s3a-container-right--2c {
	background: url('../img/bg-s3--2c.png') no-repeat 50% 50%;
	background-size: cover;
}

.s3a-container-right.s3a-container-right--2d {
	background: url('../img/bg-s3--2d.png') no-repeat 50% 50%;
	background-size: cover;
}

.s3c-container-right.s3c-container-right--1 {
	background: url('../img/bg-s3c--b.png') no-repeat 50% 50%;
	background-size: contain;
}

.s3c-container-right.s3c-container-right--2 {
	background: url('../img/bg-s3c--c.png') no-repeat 50% 50%;
	background-size: contain;
}

.s3c-container-right.s3c-container-right--3 {
	background: url('../img/bg-s3c--d.png') no-repeat 50% 50%;
	background-size: contain;
}

.s3c-container-right.s3c-container-right--4 {
	background: url('../img/bg-s3c--e.png') no-repeat 50% 50%;
	background-size: contain;
}

.s3c-container-right.s3c-container-right--5 {
	background: url('../img/bg-s3c--f.png') no-repeat 50% 50%;
	background-size: contain;
}

.s3c-container-right.s3c-container-right--6 {
	background: url('../img/bg-s3c--g.png') no-repeat 50% 50%;
	background-size: contain;
}

.s3c-container-right.s3c-container-right--7 {
	background: url('../img/bg-s3c--h.png') no-repeat 50% 50%;
	background-size: contain;
}

.s3c-container-right.s3c-container-right--8 {
	background: url('../img/bg-s3c--i.png') no-repeat 50% 50%;
	background-size: contain;
}

.s3c-container-right.s3c-container-right--9 {
	background: url('../img/bg-s3c--j.png') no-repeat 50% 50%;
	background-size: contain;
}

.s3c-container-right.s3c-container-right--10 {
	background: url('../img/bg-s3c--k.png') no-repeat 50% 50%;
	background-size: contain;
}

.s3b-container-right {
	background: url('../img/bg-s3--2a.png') no-repeat 50% 50%;
	background-size: contain;
	height: 85vh;
}

.s3c-container-right {
	background: url('../img/bg-s3c--a.png') no-repeat 50% 50%;
	background-size: contain;
	height: 85vh;
}

.videoContainer {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.videoContainer__video {
	width: 90%;
	height: 90%;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.fullscreen-video-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	z-index: -2;
}
.fullscreen-video-container video {
	min-height: 100%;
	min-width: 100%;
	/* left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%); */
}

.header-overlay {
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: -1;
	background: rgba(0, 0, 0, 0.5);
	opacity: 0.85;
}

.submenu-link {
	@apply flex;
}

.submenu-link p {
	position: relative;
}

.submenu-link p::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 2px;
	bottom: -3px;
	left: 0;
	background-color: #325280;
	visibility: hidden;
	transform: scaleX(0);
	transition: all 0.3s ease-in-out 0s;
	/* border-bottom: 2px solid #325280; */
}

.submenu-link p:hover::before {
	visibility: visible;
	transform: scaleX(1);
}

.navigation__nav,
.navigation__checkbox {
	display: none;
}

.team-box {
	cursor: pointer;
}

.team-box > * {
	display: none;
}

.team-box:hover > * {
	display: block;
}

.team-box--2 {
	background: url('../img/team-2.jpg') no-repeat 70% 50%;
	background-size: cover;
}

.team-box--2:hover {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url('../img/team-2.jpg') no-repeat 70% 50%;
	background-size: cover;
}

.team-box--3 {
	background: url('../img/team-3.jpg') no-repeat 70% 50%;
	background-size: cover;
}

.team-box--3:hover {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url('../img/team-3.jpg') no-repeat 70% 50%;
	background-size: cover;
}

.team-box--5 {
	background: url('../img/team-5.jpg') no-repeat 40% 50%;
	background-size: cover;
}

.team-box--5:hover {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url('../img/team-5.jpg') no-repeat 40% 50%;
	background-size: cover;
}

.team-box--6 {
	background: url('../img/team-6.jpg') no-repeat 40% 50%;
	background-size: cover;
}

.team-box--6:hover {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url('../img/team-6.jpg') no-repeat 40% 50%;
	background-size: cover;
}

.team-box--7 {
	background: url('../img/team-7.jpg') no-repeat 60% 50%;
	background-size: cover;
}

.team-box--7:hover {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url('../img/team-7.jpg') no-repeat 60% 50%;
	background-size: cover;
}
.team-box--8 {
	background: url('../img/team-8.jpg') no-repeat 60% 50%;
	background-size: cover;
}

.team-box--8:hover {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url('../img/team-8.jpg') no-repeat 60% 50%;
	background-size: cover;
}
/* MOBILE STYLES */

@media only screen and (max-width: 600px) {
	html {
		overflow: visible !important;
	}

	#fullpage {
		width: 100vw;
	}

	.fullscreen-video-container,
	.header-overlay,
	.info-container,
	.social-container,
	.arrow,
	#fp-nav,
	#logo-b.logo,
	.s1-content-box p br,
	.footer-info-text,
	.modal-slider {
		display: none;
	}
	.section--1-xs {
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
			url('../img/bg-s3.jpg') no-repeat 50% 50%;
		background-size: cover;
	}

	.flags-container {
		top: 100px;
		left: 75%;
	}

	.logo {
		width: 135px;
		margin-right: 15rem;
	}
	nav#navigation {
		background: white;
		padding: 1.25rem 2.25rem;
		width: 100vw;
	}

	.divider--3 {
		display: block;
	}

	.nav-container {
		width: 100%;
		justify-content: space-between;
	}
	#logo-c {
		display: block !important;
	}
	.s1-content-box {
		max-width: 100%;
		margin: 0;
		padding: 7rem 4rem;
		margin-top: 4rem;
	}

	.s1-content-box .text-justify {
		max-width: 100%;
	}

	.s1-content-box h1 {
		font-size: 2.5rem;
		margin-bottom: 3rem;
	}

	.text-hero {
		font-size: 2rem;
	}
	/* .s1-content-box p {
		font-size: 1rem;
		line-height: 1.1;
	} */

	.text-justify {
		max-width: 60%;
	}

	.play-video > * {
		font-size: 1rem;
		font-weight: normal;
	}

	.play-video i {
		font-size: 1.5rem;
	}

	.divider {
		border-bottom: 4px solid white;
	}

	.bars-container {
		display: block;
		font-size: 1.5rem;
		cursor: pointer;
	}

	.navigation__button {
		background-color: white;
		height: 3rem;
		width: 3rem;
		position: fixed;
		top: 3%;
		right: 5%;
		border-radius: 50%;
		z-index: 1000;
		text-align: center;
		cursor: pointer;
	}

	.navigation__checkbox:checked ~ p {
		color: red;
	}

	.navigation__icon {
		position: relative;
		margin-top: 1.5rem;
	}
	.navigation__icon,
	.navigation__icon::before,
	.navigation__icon::after {
		width: 1rem;
		height: 2px;
		background-color: #325280;
		display: inline-block;
	}

	.navigation__icon::before,
	.navigation__icon::after {
		content: '';
		position: absolute;
		left: 0;
		transition: all 0.2s;
	}

	.navigation__icon::before {
		top: -0.4rem;
	}

	.navigation__icon::after {
		top: 0.4rem;
	}

	.navigation__checkbox:checked + .navigation__button .navigation__icon {
		background-color: transparent;
	}

	.navigation__checkbox:checked + .navigation__button .navigation__icon::before {
		top: 0;
		transform: rotate(135deg);
	}

	.navigation__checkbox:checked + .navigation__button .navigation__icon::after {
		top: 0;
		transform: rotate(-135deg);
	}

	.navigation__background {
		height: 3rem;
		width: 3rem;
		border-radius: 50%;
		position: fixed;
		top: 5%;
		right: 5%;
		background-image: radial-gradient(white, white);
		z-index: 999;
		transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
	}

	.navigation__checkbox:checked ~ .navigation__background {
		transform: scale(200);
	}

	.navigation__nav {
		display: block;
		height: 100vh;
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		opacity: 0;
		visibility: hidden;
		width: 0;
		transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	}

	.navigation__checkbox:checked ~ .navigation__nav {
		opacity: 1;
		width: 100%;
		visibility: visible;
	}

	.navigation__list {
		list-style: none;
		position: absolute;
		top: 50%;
		left: 60%;
		transform: translate(-50%, -50%);
		text-align: left;
		width: 100%;
	}

	.navigation__list a {
		text-decoration: none;
		color: #325280;
	}

	.social-container-xs {
		margin-top: 4rem;
	}

	.social-container-xs a {
		font-size: 1.25rem;
	}

	.navigation__item {
		@apply mb-6;
	}

	.navigation__item:hover {
		font-weight: bold;
	}

	.divider--2 {
		width: 60px;
		border-bottom: 3px solid #325280;
		margin-bottom: 2rem;
	}

	.s2-bg-content {
		display: none !important;
	}

	.myContainer {
		display: block;
	}

	.s2-xs-container {
		padding: 4rem 3.5rem;
		position: relative;
		margin-top: 0;
	}

	.s2-xs-container .text-justify {
		max-width: 100%;
	}

	.s2-xs-container h1 {
		font-size: 1.75rem;
	}

	.s2-xs-container p {
		font-size: 0.85rem;
	}

	.s2-xs-container ul li p {
		font-size: 0.9rem;
	}

	.horizontal-bar {
		display: block;
		position: absolute;
		top: 15%;
		right: 0;
		height: 60vh;
		border-right: 7px solid #325280;
	}

	.bottom-bar {
		display: block;
		position: absolute;
		bottom: 0;
		right: 20%;
		width: 60vw;
		border-bottom: 15px solid #325280;
	}

	ul.s2-p-content {
		display: grid;
		grid-template-areas: 'p' 'i';
	}

	.section--3 {
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
			url('../img/bg-s2b.jpg') no-repeat 50% 50%;
		background-size: cover;
	}

	.s3-container {
		max-width: 80vw;
		padding: 4rem 0;
	}

	.s3-box-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.s3-container h1 {
		font-size: 1.75rem;
		margin-bottom: 0.75rem;
	}

	.s3-card:hover {
		transform: none;
	}

	.s3-box-container .s3-card {
		width: 100%;
	}

	.s3-box-container .s3-card:not(:last-child) {
		margin-bottom: 2rem;
	}

	.s3-box-container .s3-card h4 {
		margin-bottom: 0.2rem;
	}

	.s3-box-container .s3-card h4 br {
		display: none;
	}

	.s3-box-container .s3-card p {
		font-size: 0.75rem;
	}

	.s3a-container-right {
		display: none;
	}
	.modal-content {
		max-width: 90vw;
		margin-top: 2rem;
		margin-bottom: -1rem;
	}

	.s3-modal-container {
		display: flex;
		justify-content: center;
		align-content: center;
		padding: 1rem 1.5rem;
		width: 100%;
		height: 100%;
	}

	.close {
		z-index: 2000;
		color: #325280;
		position: absolute;
		top: 5%;
		right: 5%;
		font-size: 35px;
	}

	.s4-container {
		max-width: 80vw;
		margin-top: 0;
		padding: 4rem 0 2rem 0;
		display: flex;
		flex-direction: column;
		position: relative;
	}

	.s4-box {
		display: grid;
		grid-template-areas: 't t t' 'l l l' 'l l l';
		grid-column-gap: 2rem;
	}

	.s4-box:not(:last-child) {
		margin-bottom: 1.4rem;
	}

	.s4-box h3 {
		flex: 1 100%;
		text-align: left;
		font-size: 1.1rem;
		margin-bottom: 1.5rem;
		grid-area: t;
	}

	.s4-box img {
		margin: 0;
		grid-area: l;
		/* width: 27.5%; */
		/* height: 50%; */
	}

	#prov-6 {
		filter: grayscale(100%);
	}

	.s4-box:last-child img {
		margin-bottom: 1.5rem;
	}

	.s4-box.s4-box--2 {
		display: grid;
		grid-template-areas: 't t t' 'l l l' 'l l l';
		grid-column-gap: 2rem;
	}

	.s4-box.s4-box--2 h3 {
		flex: 1 100%;
		text-align: left;
		font-size: 1.1rem;
		margin-bottom: 1.5rem;
		grid-area: t;
	}

	.s5-container-right {
		display: none;
	}

	.s5-container {
		max-width: 80vw;
		margin: 0 auto;
		padding-bottom: 4rem;
		display: flex;
		justify-content: center;
		align-content: center;
	}

	.s5-container-left {
		margin: 0;
	}

	.s5-container-left h1 {
		font-size: 1.75rem;
	}

	.s5-container-left p {
		font-size: 0.85rem;
	}

	.s5-container-left ul li p {
		font-size: 0.9rem;
	}

	.s5-container-left .text-justify {
		max-width: 100%;
	}

	.s6-container {
		max-width: 80vw;
		margin-top: 0;
		padding-bottom: 2rem;
	}

	.myContainer .horizontal-bar {
		height: 40vh;
	}

	.modal-s2a-2 .s3-modal-container ul {
		padding-left: 1rem;
		grid-column-gap: 1.5rem;
	}

	.s6-container .s6-w-container {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.s6-container .s6-w-container i {
		font-size: 0.45rem;
	}

	.s6-container h3 {
		margin-bottom: 1.5rem;
	}

	.s6-container .s6-w-container li {
		display: flex;
		align-items: center;
	}

	.s3-modal-container .text-justify {
		max-width: 100%;
	}

	.s6-container .s6-w-container li span {
		font-size: 0.8rem;
	}

	.s6-container > div {
		margin-bottom: 1rem;
	}
	.s6-container .footer-map img {
		height: 20vh;
	}

	.footer-icon {
		display: block;
		color: #325280;
		font-size: 1.5rem;
		margin-bottom: 0.75rem;
	}
	.s6-info-box {
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: baseline;
		align-items: center;
	}

	.s6-info-box:not(:last-child) {
		margin-right: 1.25rem;
	}

	.section--4,
	.section--5 {
		position: relative;
	}

	.section--5 .horizontal-bar {
		height: 40vh;
	}
}

@media only screen and (max-width: 1250px) {
	.section--2 {
		margin-left: 2.75rem;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
			url('../img/bg-s2--a.jpg') no-repeat 100% 50%;
		background-size: cover;
	}

	.arrowDown {
		bottom: 215px;
		transform: rotate(45deg);
	}

	.arrowUp {
		top: 215px;
		transform: rotate(-135deg);
	}
}
